import { IonContent, IonPage } from "@ionic/react";

// Hooks
import { useCallback, useState, useMemo, useEffect } from "react";
import useLocalize from "../../locales/hook";
import { useControlBallot } from "../../redux/receipt/hooks";

// Components

import IconTypes from "../../utils/types/IconTypes";
import TabItem from "../../components/TabItem/TabItem";
import Button from "../../atoms/Button/Button";
import Image from "../../atoms/Image/Image";
import TextInput from "../../atoms/TextInput/TextInput";

// Types & Styles
import "./Verification.scss";
import VisualizeBallot from "../../components/VisualizeBallot/VisualizeBallot";

export interface ballot {
  listId: string;
  crossedOutCandidateIds: number[]; // Ensure this matches your requirements
  voteSector: string;
  scrutinId: string;
  apiKeyThirdParty: string;
  listCandidateId: string;
  domain: string;
}

export interface ballotPrint {
  print: string;
  apiKeyThirdParty: string;
  domain: string;
}
const Verification: React.FC<unknown> = () => {
  const { checkBallot, receipt, isLoading } = useControlBallot();

  const t = useLocalize();

  const TABS_TYPES = {
    PRINT: "PRINT",
    INTEGRITY: "INTEGRITY",
    LOADING: "LOADING",
  };

  const TABS = [
    { id: TABS_TYPES.PRINT, label: "check_print" },
    { id: TABS_TYPES.INTEGRITY, label: "check_integrity" },
  ];
  const initialBallot: ballot = {
    listId: "",
    crossedOutCandidateIds: [],
    voteSector: "",
    scrutinId: "",
    apiKeyThirdParty: "",
    listCandidateId: "",
    domain: "",
  };
  const [ballotPrint, setBallotPrint] = useState(
    '{ print: "", apiKeyThirdParty: "",domain: ""}'
  );

  const [ballot, setBallot] = useState<ballot>(initialBallot); // État pour l'objet ballot
  const [jsonString, setJsonString] = useState(
    '{ listId: "", crossedOutCandidateIds: [2], voteSector: "Lille",domain: "",apiKeyThirdParty: ""}'
  );
  const [isError, setIsError] = useState(false);
  const [lastTab, setLastTab] = useState(TABS_TYPES.PRINT);
  const [selectedTab, setSelectedTab] = useState(TABS_TYPES.LOADING);

  const [ballotJson, setBallotJson] = useState<ballot>({
    listId: "0",
    crossedOutCandidateIds: [],
    voteSector: "default",
    scrutinId: "",
    apiKeyThirdParty: "",
    listCandidateId: "",
    domain: "",
  }); // Initialize as undefined

  useEffect(() => {
    if (selectedTab === TABS_TYPES.LOADING) {
      setTimeout(() => {
        setSelectedTab(lastTab);
      }, 250);
    }
  }, [lastTab, selectedTab]);

  const handleClickTab = useCallback(
    (tab) => {
      if (!isError) {
        setLastTab(selectedTab);
        setSelectedTab(tab);
      }
    },
    [isError, selectedTab]
  );
  const handleClickCheck = useCallback(
    (e) => {
      e.stopPropagation();
      const parsedObject = JSON.parse(ballotPrint);

      checkBallot(parsedObject);
    },
    [ballotPrint, checkBallot]
  );

  // Convertissez la chaîne JSON en objet ballot
  const parseJson = useCallback(() => {
    try {
      const parsedObject = JSON.parse(jsonString);
      setBallot(parsedObject);
    } catch (error) {
      console.error("Invalid JSON format:");
      setBallot(initialBallot); // Réinitialiser l'objet ballot en cas d'erreur
    }
  }, [jsonString, initialBallot]);

  const status = useMemo(() => {
    if (isLoading || receipt?.isError === undefined) return "";
    if (!receipt.isError) return "valid";
    else return "invalid";
  }, [receipt, isLoading]);

  const icon = useMemo(() => {
    if (isLoading) return IconTypes.LOADER;
    if (status === "valid") return IconTypes.CHECK_CIRCLE;
    if (status === "invalid") return IconTypes.X_CIRCLE;
    return undefined;
  }, [isLoading, status]);

  return (
    <IonPage>
      <IonContent id="Verification" scrollEvents>
        <div className="container">
          <div className="pageTitle">{t("welcome_message")}</div>

          <div className="tabsContainer">
            {TABS.map((tab) => (
              <TabItem
                isDisabled={isError}
                selected={
                  (selectedTab === TABS_TYPES.LOADING
                    ? lastTab
                    : selectedTab) === tab.id
                }
                key={tab.id}
                title={t(tab.label)}
                onClick={handleClickTab}
                value={tab.id}
              />
            ))}
          </div>
          <div className="boxContain">
            {selectedTab === TABS_TYPES.PRINT && (
              <div className="box">
                <div className="pageTitle">
                  {t("confirm_ballot_presence_title")}
                </div>

                <div className="titleControl">
                  {t("level3_control_activated")}
                </div>

                <div className="ballotControlContent">
                  <Image src="confirm-logout" />

                  <div className={"fieldContainer " + status}>
                    <TextInput
                      label={t("ballot_print")}
                      autocomplete="off"
                      valueKey="username"
                      onChange={setBallotPrint}
                      icon={icon}
                      maxLength={300}
                    />
                    <div className="buttonContainer">
                      <Button
                        label={t("verify")}
                        fill
                        onClick={handleClickCheck}
                      />
                    </div>
                  </div>
                  <div className={"infoControl "}>
                    {t(status && `control_ballot_${status}`)}
                  </div>
                </div>
              </div>
            )}

            {selectedTab === TABS_TYPES.INTEGRITY && (
              <div className="box">
                <div className="pageTitle">{t("check_integrity")}</div>

                <VisualizeBallot ballot={ballot} />

                <div className="ballotControlContent">
                  <div className={"fieldContainer"}>
                    <TextInput
                      label={t("json_ballot")}
                      autocomplete="off"
                      valueKey="ballot"
                      onChange={setJsonString} // Corrected onChange handler
                      icon={icon}
                      maxLength={800}
                    />

                    <div className="buttonContainer">
                      <Button
                        disabled={!ballotJson}
                        label={t("verify")}
                        fill
                        onClick={parseJson}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Verification;

import { useCallback } from "react";
import { useAppSelector } from "../../init/store";

export const useCandidate = (candidateId: number) => {
  const candidate = useAppSelector(
    (state) => state.candidates.candidatesById[candidateId]
  );

  return { candidate };
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAxios } from "../../utils/lib/requestAxios";
import { ballot } from "../../components/Verification/Verification";
import { decryptObject } from "../../utils/security/encrypt";
import { renderUrlApi } from "../../utils/lib/utils";

// Get Candidates
export const requestCandidates = createAsyncThunk<any, ballot>(
  "scrutins/requestCandidates",

  async (ballot, { rejectWithValue }) => {
    const url = renderUrlApi(ballot.domain);

    const response: any = await setAxios(
      url + `/thirdParty/listCandidate`,
      ballot
    );

    if (response.error) {
      console.log(response.error);
      return rejectWithValue(response.error.response.status);
    } else {
      const { encryptedData, IV } = response.data;

      const decryptedData = decryptObject(
        encryptedData,
        ballot.apiKeyThirdParty,
        IV
      );

      return {
        ballot,
        data: decryptedData,
      };
    }
  }
);

export const requestCandidatesState = (builder) => {
  builder.addCase(requestCandidates.fulfilled, (state, { payload }) => {
    const { data } = payload;

    state.listsById[data.id] = {
      ...(state.listsById[data.id] || {}),
      candidates: data.candidates.map(({ user: { id } }) => id),
      candidateIds: data.candidates.map(({ id }) => id),
      poll: data.poll,
      listInfo: {
        title: data.title,
        logoUrl: data.logoUrl,
        professionOfFaithUrl: data.professionOfFaithUrl,
      },
      college: data.poll.college,
    };
  });
};
